.work__container {
    min-height: 100vh;
    background-color: #121212;
    cursor: none;
}
.work__container a {
    cursor: none;
}

.work__tags { 
    padding: 0 16px;
    display: flex; 
    margin-top: 45px;
    max-width: 950px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    flex-wrap: wrap;
    display: none;
}
.work__tag {
    cursor: none;
    border: 1px solid #FCFCFC;
    color: #FCFCFC;
    border-radius: 60px;
    padding: 4px 12px;
    width: fit-content;
    margin-right: 12px;
    margin-bottom: 12px;

    font-family: 'Noto Sans Display';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    transition: background-color 300ms, color 300ms;
}
.work__tag--active {
    background-color: #FCFCFC; 
    color: #121212;
}
.work__tag-name { display: inline; }
.work__tag-count { display: inline;}

.work__content {
    margin: 54px auto 64px auto;
    grid-gap: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fit,calc(50% - 32px));
    grid-template-rows: unset;
    justify-content: center;
    max-width: 1292px;
    padding: initial;
}
.work__tile {
    transition: padding .2s;
    height: 100%;
    width: 100%;
    margin: auto;
    max-width: 974px;
    overflow: hidden;
    background-color: #202020;
    position: relative;
}
.work__tile-background {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60.89%, #000000 118.42%);
    padding: 40px 24px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    opacity: 0;
    transition: all 300ms;
}
.work__tile-title {
    color: white;
    font-family: 'Noto Sans Display';
    font-size: 24px;
    font-weight: 700;
}
.work__tile:hover .work__tile-background {
    opacity: 1;
}
.work__tile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.work__tile:hover img {
    transform: scale(1.1);
}
.work__tile--transition-0 {
    transition: all 250ms ease 0ms;
    transform: translate(30%, 0);
    opacity: 0;
}
.work__tile--transition-1 {
    transition: all 250ms ease 0ms;
    transform: translate(-30%, 0);
    opacity: 0;
}
.work__tile--transition-2 {
    transition: all 250ms ease 0ms;
    transform: translate(0, 30%);
    opacity: 0;
}
.work__tile--transition-3 {
    transition: all 250ms ease 0ms;
    transform: translate(0, -30%);
    opacity: 0;
}
.work__tile-cover { 
    transition: all 250ms ease inherit;
    transform: translate(0, 0);
    opacity: 1;
}
.work__tile-reset {transition: none !important;}

@media screen and (min-width: 645px) {
    .work__content {
        min-height: unset;
        grid-template-columns: repeat(auto-fit,308px);
        grid-template-rows: repeat(auto-fit,  505px);
        grid-gap: 20px;
        margin: 54px auto 200px auto;
    }
    .work__tile {
        height: 505px;
        width: 308px; 
    }
    .work__tags { padding-inline: 12px; display: flex;}

}
