@font-face {
    font-family: 'Noto Sans Display';
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}
@font-face {
    font-family: "Noto Sans Display Italic";
    font-style: italic, oblique;
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
