@font-face {
    font-display: block;
    font-family: 'Noto Sans Display';
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}
@font-face {
    font-display: block;
    font-family: "Noto Sans Display Italic";
    font-style: italic, oblique;
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}
html {
}
body {
    margin: 0;
    font-family: 'Noto Sans Display';
}

/* --- cursors --- */
.cursor {
    display: none;
    position: absolute;
    height: 42px;
    width: 42px;
    background-image: url("../assets/cursor-fill-w.svg");
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 20px;
    top: var(--y, -21px);
    left: var(--x, -21px);
    transform: translate(-50%, -50%);
    z-index: 90;
    pointer-events: none;
    mix-blend-mode: difference;
    transition: width 200ms, height 200ms;
}
.cursor--noblend {
    mix-blend-mode: unset !important;
    background-image: url('../assets/cursor--outline.svg') !important;
    background-repeat: no-repeat;
    background-size: contain;
}
.cursor--noblend-hover {
    mix-blend-mode: unset !important;
    background-image: url('../assets/cursor--outline.svg') !important;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 60px;
}

.contact-hover {
    mix-blend-mode: unset !important;
    height: 80px !important;
    width: 80px !important;
    background-image: url('../assets/hover-3.svg') !important;
    background-repeat: no-repeat;
    background-size: contain;
    transition: width 200ms, height 200ms;
}
.lang-hover {
    height: 60px !important;
    width: 60px !important;
    transition: width 200ms, height 200ms;
}

/* --- navbar --- */
.navbar__container {
    z-index: 50;
    width: 100%;
}
.navbar__container--fixed {
    background: #FCFCFC;
    position: fixed;
    top: 0;
}
.navbar {
    width: 100vw;
    max-width: 950px;
    /*padding-top: 12px;*/
    justify-content: flex-end;
    left: 50%;
    position: relative;
    transform: translate(-50%);
    overflow: auto;
    height: auto;
    font-family: 'Noto Sans Display';
    font-size: 14px;
    display: flex;
    align-items: center;
    align-items: center;
}
.navbar a { cursor: none; }
.navbar--light .navbar__link { color: #121212; }
.navbar--logo { padding-top: 16px; padding-bottom: 16px;}
.navbar__home { flex: auto; box-sizing: border-box; padding-left: 40px; height: 27px; align-self: center;}
.navbar__home img { width: 24px; }
.navbar__home.responsive {display: none;}

.navbar__links {
    text-align: center;
    display: flex;
    /*justify-content: space-between;*/
    justify-content: flex-end;
    align-items: center;
    max-width: 450px;
    width: calc(100% - 24px);;
    flex-shrink: 1;
}
.navbar__links.responsive {
    justify-content: space-between;
}
.navbar__link {
    height: 0;
    width: 0;
    line-height: 43px;
    overflow: hidden;
    transition: height 1s;
    text-decoration: none;
    color: #FCFCFC;
    text-transform: uppercase;
    flex: auto;
    transition: height 200ms ease-in 150ms;
    cursor: none;
}
.navbar__link.responsive { display: inline; margin-left: unset; height: 43px;}
.navbar__link--active {text-decoration: underline; font-weight: 700;}

.navbar__burger {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    padding: 0;
    cursor: pointer;
}
.navbar__burger-icon {
    display: block;
    height: 43px;
    width: 43px;
}
.navbar__burger-img {
    margin-top: 18px;
    margin-left: 17px;
    position: absolute;
    display: none;
}

.nav-home {
    flex: auto;
}
.nav-home.responsive { display: none; /*transition: display 1s linear 1s;*/}

.lang__toggle {
    display: flex;
    margin-left: 8px;
    max-width: 950px;
    overflow: auto;
    color: #FCFCFC;
    position: relative;
}
.lang__option {
    float: left;
    opacity: 0.5;
    padding: 0px 5px;
    text-decoration: none;
    color: #FCFCFC;
    cursor: none;
}
.lang__divider {
    width: 8px;
    float: left;
    text-align: center;
}
.lang--active {
    opacity: 1;
}
.lang__en {
    padding-left: 9px;
}
.lang__kr {
    padding-right: 20px;
    white-space: nowrap;
}

.navbar__divider {
    height: 1px;
    width: 100vw;
    opacity: 0.2;
    background-color: #121212;
}
.navbar__divider--dark {
    background-color: #FCFCFC;
}

/* --- Footer --- */
.footer {
    width: 100%;
    background-color: #121212;
}
.footer input:focus {
    outline: none;
}
.footer input:active,
.footer input:focus,
.footer input:hover,
{
    background-color: #414141;
}


/*
input:-internal-autofill-selected {
    background-color: #414141;
    color: #FCFCFC;;
}*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #414141 inset !important;
    -webkit-text-fill-color: #FCFCFC;
    caret-color: #FCFCFC;
}

.footer__content {
    max-width: 975px;
    padding-top: 40px;
    position: relative;
    left: 50%;
    transform: translate(-50%);

    display: flex;
    flex-direction: column;
}
.footer__t { display: flex; border-bottom: 1px solid #FCFCFC; padding-bottom: 26px; }
.footer__b { padding: 24px 0; display: flex;}
.footer__l { flex: auto; }
.footer__r { display: none;}

.footer__logo { 
    width: 30px;
    height: 34px;
    margin-bottom: 32px; 
    background-repeat: no-repeat; 
    background-image: url("../assets/logo--light.svg");
    background-size: contain;

    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.footer__links {   
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.footer__link {
    max-width: max-content;
    flex: auto;
    text-align: center;
    margin: 0 13px;
    text-decoration: none;
    color: #FCFCFC;
    text-transform: uppercase;
    font-weight: 380;
    margin-bottom: 10px;
    cursor: none;
}
.footer__link-lang {
    text-decoration: underline;
}
.footer__contact-form {}
.footer__input-container {
    display: flex;
    background-color: rgba(252, 252, 252, 0.2);
    border-radius: 8px;
}
.footer__contact-btn {
    background-color: transparent;
    border: none;
    padding-right: 12px;
    cursor: none;
}
.footer__contact-btn-icon {
    display: block;
}
.footer__input-label { 
    font-family: 'Noto Sans Display';
    font-size: 20px;
    font-weight: 500;
    color: #FCFCFC;
    margin-bottom: 24px;
 }
.footer__error-msg {
    color: #FF7676;
    font-family: 'Noto Sans Display';
    font-size: 12px;
    line-height: 24px;
    padding-left: 12px;
}
.footer__input {
    width: 255px;
    height: 40px;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    padding: 0 12px;
    box-sizing: border-box;
    color: #FCFCFC;
}
.footer__copyright { 
    font-family: 'Noto Sans Display';
    font-size: 14px;
    font-weight: 390;
    color: #FCFCFC;
    flex: auto;
    text-align: center;
}
.footer__social {
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    margin-left: 16px;
}
.footer__social-icon {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-height: 850px) and (orientation: landscape) {
    .lang__option {
        padding: 0;
        padding: 0 5px 0 0;
    }
    .lang__divider {
        margin-top: 0;
        padding-right: 5px;
    }
}
@media screen and (min-width: 950px) {
    .cursor { display: block; }
    .navbar{ font-size: 16px; padding-top: 63px; }
    .nav-home.responsive { display: block; }
    .navbar__home {padding-left: 0; height: unset;}
    .navbar__home img { width: unset; }
    .navbar__link { display: block; height: unset; }
    .navbar__burger { display: none; }
    .navbar__divider { margin-top: 18px; }
    .lang__toggle {
        margin-left: 0;
    }   
    .lang__en {
        padding-left: 9px;
    }
    .lang-hover {
        height: 60px !important;
        width: 60px !important;
        transition: width 200ms, height 200ms;
    }
    .footer-col {
        width: 60%;
    }
    .footer__logo {
        width: 142px; 
        height: 40px;
        background-image: url("../assets/logo/horizontal-lt.svg");
        left: unset;
        transform: none;
    }
    .footer__r { display: block; }
    .footer__links { max-width: 520px; justify-content: left; }
    .footer__link { flex: auto; margin: 0; text-align: left; max-width: unset;}
    .footer__t { padding-bottom: 48px; }
    .footer__copyright { text-align: left; }
}


