.error-container {
    width: 100vw;
    height: 100vh;
    background-color: #FCFCFC;
}
.error {
    position: relative;
    width: 100%;
    height: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.error-title {
    font-family: 'Noto Sans Display';
    font-weight: 300;
    font-size: 52px;
    width: fit-content;
    left: 50%;
    position: relative;
    transform: translate(-50%);
}
.error-em {
    font-weight: 500;
}  
.error-message {
    font-family: 'Noto Sans Display';
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    white-space: pre;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.error-redirect {
    background: #121212;
    border: 1px solid #121212;
    border-radius: 60px;
    padding: 8px 24px;

    color: white;
    font-family: 'Noto Sans Display';
    font-weight: 600;
    font-size: 24px;
    margin-top: 40px;

    display: inline-block;
    text-decoration: none;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}
.error-message a:link,
.error-message a:visited,
.error-message a:hover,
.error-message a:active {
    color: black;
}
.logo-201 {
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

